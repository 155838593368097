import styled from "styled-components"

import { EmptyState } from "src/components/EmptyState"
import { GuestCommunicationAddButton } from "src/components/GuestCommunication/GuestCommunicationAddButton"
import { IGuestCommunicationRuleTemplate } from "src/data/guestCommunication/types/guestCommunicationTypes"
import { Routes } from "src/router/routes"
import { minimumGray } from "src/ui/colors"
import Icon from "src/ui/icons/scheduled-message-bubble.svg"
import { InternalLink } from "src/ui/Link/InternalLink"

export function GuestCommunicationEmptyState({
  templates,
  loading,
}: {
  templates?: IGuestCommunicationRuleTemplate[]
  loading?: boolean
}) {
  return (
    <EmptyState
      icon={<Icon />}
      title="No messages yet"
      body={
        <>
          <Description>
            <p>
              Automatically send guests a welcome message, wifi details,
              check-out instructions and more.
            </p>
            <p>
              To contact guests when noise levels have been exceeded visit{" "}
              <InternalLink to={Routes.MonitoringSettings.location().pathname}>
                Settings
              </InternalLink>
              .
            </p>
          </Description>
          <GuestCommunicationAddButton
            templates={templates}
            loading={loading}
          />
        </>
      }
    />
  )
}

const Description = styled.div`
  color: ${minimumGray};
`
