import { useState } from "react"
import styled from "styled-components"

import { Divider, Switch, TextField } from "@material-ui/core"

import { RouteNotFound } from "src/components/RouteNotFound/RouteNotFound"
import { HREF_MINUT_HELP_SHORTCODES } from "src/constants/hrefs"
import { channelsFriendlyName } from "src/data/guestCommunication/logic/guestCommunicationLogic"
import { useFetchGuestCommunicationChannels } from "src/data/guestCommunication/queries/guestCommunicationQueries"
import {
  IGuestCommunicationError,
  IPostGuestCommunicationRule,
  IShortcode,
} from "src/data/guestCommunication/types/guestCommunicationTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { InfoBox } from "src/ui/InfoBox/InfoBox"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { TextEditor } from "src/ui/TextEditor/TextEditor"
import { stringSplice } from "src/utils/genericUtil"

import { IScheduledForChangeEvent, ScheduledFor } from "./ScheduledFor"
import { SelectShortcode } from "./SelectShortcode"

export function RuleForm({
  formId,
  ruleInitial,
  onUpdate,
  onSubmit,
}: {
  formId?: string
  ruleInitial: IPostGuestCommunicationRule
  onUpdate: ({
    formData,
    isDirty,
  }: {
    isDirty: boolean
    formData?: IPostGuestCommunicationRule
  }) => void
  onSubmit: (
    formData: IPostGuestCommunicationRule,
    onSubmitError: (error: IGuestCommunicationError) => void
  ) => void
}) {
  const fetchChannels = useFetchGuestCommunicationChannels({})
  const [formData, _setFormData] =
    useState<IPostGuestCommunicationRule>(ruleInitial)
  const [caretPos, setCaretPos] = useState(formData.content?.length ?? 0)
  const { t, langKeys } = useTranslate()

  const [error, setError] = useState<React.ReactNode>(null)

  function emitUpdate(formData: IPostGuestCommunicationRule) {
    const isDirty = JSON.stringify(formData) !== JSON.stringify(ruleInitial)
    onUpdate({ isDirty })
  }

  function updateFormData(newFormData: Partial<IPostGuestCommunicationRule>) {
    _setFormData((data) => {
      const newData = { ...data, ...newFormData }
      emitUpdate(newData)
      return newData
    })
  }

  function handleContentChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    if (error) {
      setError(null) // Reset possible wrong format errors
    }
    const newFormData = { content: e.target.value }
    updateFormData(newFormData)
  }

  function setName(name: string) {
    const newFormData = { ...formData, name }
    updateFormData(newFormData)
  }

  function setEnabled(enabled: boolean) {
    const newFormData = { ...formData, enabled }
    updateFormData(newFormData)
  }

  function handleSelectShortcode(shortcode: IShortcode) {
    const content = stringSplice(
      formData.content,
      caretPos,
      `{{${shortcode.shortcode}}}`
    )
    updateFormData({ content })
  }

  function handleScheduleChange(schedule: IScheduledForChangeEvent) {
    updateFormData({
      trigger_offset_seconds: schedule.triggerOffsetSeconds,
      trigger: schedule.trigger,
    })
  }

  function handleSubmit() {
    onSubmit(formData, (err) => setError(err))
  }

  if (!ruleInitial) {
    return <RouteNotFound />
  }

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault()
        handleSubmit()
      }}
      id={formId}
    >
      <div>
        <Title>Name</Title>
        <TextField
          fullWidth
          value={formData.name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>

      <MessageSection>
        <EditBox>
          <Title>Message</Title>
          <TextEditor
            toolbar={<SelectShortcode onSelect={handleSelectShortcode} />}
            required
            minRows="7"
            maxRows="20"
            value={formData.content}
            onChange={handleContentChange}
            onCaretMoved={setCaretPos}
          />
        </EditBox>

        {!!error && error}

        <InfoBox title="Tip: Using shortcodes">
          <p>
            Edit and add information specific to each home (such as check-in
            instructions) in Home {">"} Shortcodes.
          </p>

          <p>Messages with empty shortcodes won't display correctly.</p>

          <ExternalLink href={HREF_MINUT_HELP_SHORTCODES}>
            {t(langKeys.learn_more)}
          </ExternalLink>
        </InfoBox>
      </MessageSection>

      <div>
        <Title>Scheduled for</Title>
        <ScheduledFor
          onChange={handleScheduleChange}
          triggerOffsetSeconds={formData.trigger_offset_seconds ?? 1}
          trigger={formData.trigger}
        />
      </div>

      <Divider />
      <div>
        <Title>Send through</Title>
        {!fetchChannels.isLoading &&
          !fetchChannels.isError &&
          channelsFriendlyName(
            formData.communication_channels,
            fetchChannels.data
          )}
      </div>

      <Divider />
      <ToggleBox>
        <Title>Enabled</Title>
        <Switch
          checked={formData.enabled}
          onChange={(e) => setEnabled(e.target.checked)}
        />
      </ToggleBox>
    </Form>
  )
}

function Title({ children }: { children: React.ReactNode }) {
  return (
    <MText variant="subtitle" marginBottom={spacing.S}>
      {children}
    </MText>
  )
}

const Form = styled.form`
  display: grid;
  grid-gap: ${spacing.XL};
  max-width: 70ch;
`

const MessageSection = styled.div`
  display: grid;
  grid-gap: ${spacing.L};
`

const EditBox = styled.div`
  max-width: 1000px;
`

const ToggleBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`
